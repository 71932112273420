exports.components = {
  "component---src-pages-countries-js": () => import("./../../../src/pages/countries.js" /* webpackChunkName: "component---src-pages-countries-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-milestones-js": () => import("./../../../src/pages/milestones.js" /* webpackChunkName: "component---src-pages-milestones-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-teammembers-js": () => import("./../../../src/pages/teammembers.js" /* webpackChunkName: "component---src-pages-teammembers-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-benefit-js": () => import("./../../../src/templates/benefit.js" /* webpackChunkName: "component---src-templates-benefit-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-featured-project-js": () => import("./../../../src/templates/featuredProject.js" /* webpackChunkName: "component---src-templates-featured-project-js" */),
  "component---src-templates-map-page-js": () => import("./../../../src/templates/mapPage.js" /* webpackChunkName: "component---src-templates-map-page-js" */),
  "component---src-templates-newsitem-js": () => import("./../../../src/templates/newsitem.js" /* webpackChunkName: "component---src-templates-newsitem-js" */),
  "component---src-templates-partner-access-page-js": () => import("./../../../src/templates/partnerAccessPage.js" /* webpackChunkName: "component---src-templates-partner-access-page-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-pressrelease-js": () => import("./../../../src/templates/pressrelease.js" /* webpackChunkName: "component---src-templates-pressrelease-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/Footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/Header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

